
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";

export default defineComponent({
  name: "account-overview",
  components: {

  },
  setup() {
    const store = useStore();
    const user = computed(()=>store.getters.currentUser);

    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Overview", [])
    })
    return  {
      user,
    }
  },
});
